import React, { useState, FC } from 'react'
import cn from 'classnames'
import Image from 'next/image'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay, Controller, Navigation, Thumbs } from 'swiper'
import styles from './Hero.module.scss'
import 'swiper/css'
import 'swiper/css/thumbs'
import { photoData, tickerData } from '../staticData'
import useWindowDimensions from "../../../customHooks/useWindowDimension";

type HeroProps = {
  link: string
  text: any
  stylesText: any
}

const Hero: FC<HeroProps> = ({ link, text, stylesText }) => {
  SwiperCore.use([Autoplay])
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore>()
  const { width } = useWindowDimensions()

  return (
    <section className={cn(styles.heroMain, styles.scalingTwo)}>
      <div className={styles.heroMainContainer}>
        <div className={styles.heroMainInfo}>
          <div className={styles.heroMainContent}>
            <h1 className={styles.heroMainHeadline}>We help you</h1>
            <div className={stylesText}>
              {width > 360 ? text :
                  <p>
                    to get the maximum <p></p> <span>benefit</span> from food supplements
                  </p>}
              </div>
            <div className={styles.heroMainFoot}>
              {width > 1024 ?
                  <span
                      className={styles.heroMainButton}
                      onClick={() => {
                        const target = document.getElementById(link)
                        if (target) {
                          window.scrollTo({
                            top:
                                target?.offsetTop +
                                document.documentElement.clientHeight / 300 - 250,
                            behavior: 'smooth',
                          })
                        }
                      }}
                  >
                Learn how
              </span>
                  :
                  <span
                      className={styles.heroMainButton}
                      onClick={() => {
                        const target = document.getElementById(link)
                        if (target) {
                          window.scrollTo({
                            top:
                                target?.offsetTop +
                                document.documentElement.clientHeight / 300 - 120,
                            behavior: 'smooth',
                          })
                        }
                      }}
                  >
                Learn how
              </span>
              }
            </div>
          </div>
        </div>
        <div className={styles.heroMainSlider}>
          <div className={styles.slider}>
            <Swiper
              className={cn(
                styles.heroMainSlide,
                styles.active,
                styles.swiperMain
              )}
              spaceBetween={20}
              thumbs={{ swiper: thumbsSwiper }}
              modules={[Navigation, Thumbs]}
              loop={photoData && photoData?.length > 1}
              autoplay
            >
              {photoData.map((image) => {
                return (
                  <SwiperSlide key={image.id} className={styles.mySlides}>
                    <div className={styles.small}>{image.id}</div>
                    <div
                      style={{ backgroundImage: `url(${image.src})` }}
                      className={cn(
                          styles.heroMainImg,
                          {[styles.heroMainImgFirst]: image.id === 1},
                          {[styles.heroMainImgSecond]: image.id === 2},
                          {[styles.heroMainImgThird]: image.id === 3}
                      )}
                    />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <div className={styles.row}>
            <Swiper
              onSwiper={setThumbsSwiper}
              spaceBetween={11}
              slidesPerView={3}
              watchSlidesProgress={true}
              modules={[Navigation, Thumbs, Controller]}
              className={styles.rowWrapper}
              centeredSlides={false}
            >
              {photoData.map((image, idx: number) => {
                return (
                  <SwiperSlide key={image.id} className={styles.column}>
                    <div className={styles.circle}>
                      <div className={styles.inner}></div>
                      <div
                        style={{ backgroundImage: `url(${image.src})` }}
                        className={`${styles.demo} ${
                          thumbsSwiper?.activeIndex === idx
                            ? styles.activeDemo
                            : ''
                        }`}
                      />
                    </div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        </div>
        <div className={styles.heroMainLine}>
          <div className={styles.heroMainLineLabel}>
            and achieve your goal together!
          </div>
          <ul className={styles.list}>
            <Swiper
              className={styles.sliderWrapper}
              spaceBetween={20}
              loop={true}
              speed={6000}
              slidesPerView={3}
              autoplay={{
                delay: 0,
                disableOnInteraction: false,
              }}
            >
              {tickerData.map((item: any) => {
                return (
                  <SwiperSlide key={item.title}>
                    <li className={styles.item}>
                      <span className={styles.image}>
                        <Image
                          src={item.src}
                          alt={item.title}
                          width={30}
                          height={30}
                          objectFit="contain"
                        />
                      </span>
                      <p className={styles.textString}>{item.title}</p>
                    </li>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Hero
